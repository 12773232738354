import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { Dynamic } from 'solid-js/web';
import { splitProps } from 'solid-js';
import type { ComponentProps, ValidComponent } from 'solid-js';

type Props<T extends ValidComponent> = ComponentProps<T> & {
	as?: T;
	class?: string;
	appearance?: 'neutral' | 'access' | 'info' | 'danger' | 'positive';
	rounded?: boolean;
};

export function Tag<T extends ValidComponent>(props: Props<T>) {
	const [, rest] = splitProps(props, ['as', 'class', 'type']);
	return (
		<Dynamic
			component={props.as ?? 'span'}
			class={twMerge(
				twJoin(
					'inline-flex w-fit items-center gap-1 text-nowrap px-2 py-1 text-xs font-semibold uppercase',
					props.appearance === 'access' && 'bg-gradient-to-r from-neutral-950 to-brand-700 text-white',
					props.appearance === 'neutral' && 'bg-neutral-200 text-neutral-800',
					props.appearance === 'positive' && 'bg-green-100 text-green-600',
					(!props.appearance || props.appearance === 'info') && 'bg-brand-100 text-brand-600',
					props.appearance === 'danger' && 'bg-red-100 text-red-600',
					props.rounded ? 'rounded-full' : 'rounded-md',
				),

				props.class,
			)}
			{...rest}
		>
			{props.children}
		</Dynamic>
	);
}
